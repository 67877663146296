import React from "react";
import "./Imprint.css";
import {FormattedMessage, useIntl} from "react-intl";
import {Button} from "@spg/component_library";
import {useKeycloak} from "@react-keycloak/web";

/**
 * A minimally styled view with the imprint for the Smart City Hub. Currently not localized (german only)
 */
function Imprint() {
  const {keycloak} = useKeycloak();
  const intl = useIntl();

  return (
    <div className="imprintContainer">
      <div className="imprintContents">
        <h1 className="typographyTypoHeadingDesktopH2">
          <FormattedMessage id="imprint.title" />
        </h1>
        <p className="typographyTypoParagraphAllDevicesP">
          <FormattedMessage id="imprint.intro" />
        </p>
        {new Array(5).fill(0).map((_, i) => (
          <React.Fragment key={i}>
            <h2 className="typographyTypoHeadingDesktopH2">
              <FormattedMessage id={`imprint.p${i + 1}.title`} />
            </h2>
            <p className="typographyTypoParagraphAllDevicesP">
              <FormattedMessage
                id={`imprint.p${i + 1}.content`}
                values={{
                  br: <br />,
                  link: (content) => (
                    <a href={"http://" + content}>{content}</a>
                  ),
                  mail: (content) => (
                    <a href={"mailto:" + content}>{content}</a>
                  ),
                }}
              />
            </p>
          </React.Fragment>
        ))}
        <div className="imprintLink">
          <Button
            label={intl.formatMessage({id: "imprint.toPortal"})}
            idBase={"imprintBack"}
            onClick={() =>
              keycloak.login({
                redirectUri: location.href.replace("/imprint", "/"),
              })
            }
            configuration={"primary"}
          />
        </div>
      </div>
    </div>
  );
}

export default Imprint;
