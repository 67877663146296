import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import "./index.css";
import App from "./App";
import keycloak from "./logic/Keycloak";
import English from "./lang/en.json";
import German from "./lang/de.json";
import ScreenSizeProvider from "./views/utility/ScreenSize";
import {PortalStyling} from "@spg/component_library/portal";
import {IntlProvider} from "react-intl";

const locale = navigator.language;

let lang = English as Record<string, string>;

if (locale.split("-")[0].toLowerCase() === "de") {
  lang = German as Record<string, string>;
}

if (process.env.NODE_ENV === "test") {
  lang = English as Record<string, string>;
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const keycloakInitOptions = {
  onLoad: "check-sso",
  redirectUri: location.href,
  pkceMethod: "S256",
};

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={keycloakInitOptions}
  >
    <React.StrictMode>
      <IntlProvider locale={locale} messages={lang}>
        <BrowserRouter>
          <ScreenSizeProvider>
            <>
              <PortalStyling />
              <App />
            </>
          </ScreenSizeProvider>
        </BrowserRouter>
      </IntlProvider>
    </React.StrictMode>
  </ReactKeycloakProvider>
);
