import React, {useEffect} from "react";
import "./App.css";
import {Route, Routes} from "react-router";
import Overview from "./views/Overview";
import Logout from "./views/utility/Logout";
import AccountManage from "./views/utility/AccountManage";
import {useKeycloak} from "@react-keycloak/web";
import {useLocation} from "react-router-dom";
import Imprint from "./views/Imprint";
import AuthErrorPage from "./views/AuthErrorPage";

function App() {
  const {keycloak, initialized} = useKeycloak();
  const pathname = useLocation().pathname;

  useEffect(() => {
    if (initialized && !keycloak.authenticated && pathname != "/imprint")
      keycloak.login({redirectUri: location.href});
  }, [initialized]);

  if (pathname == "/imprint") return <Imprint />;

  if (!initialized || !keycloak.authenticated)
    return <AuthErrorPage reason="unauthenticated" />;

  //TODO: activate when updating role concept
  // if (!keycloak.hasResourceRole("SCH Mitglied"))
  // return <AuthErrorPage reason="unauthorized" />;

  return (
    <Routes>
      <Route path="logout" element={<Logout />} />
      <Route path="account" element={<AccountManage />} />
      <Route path="*" element={<Overview />} />
    </Routes>
  );
}

export default App;
