import Keycloak from "keycloak-js";

function getBaseUrl() {
  const url = window.location.origin;

  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test" ||
    url.includes("localhost")
  ) {
    return `http://localhost:8080`;
  } else {
    return `https://idp.${window.location.hostname}`;
  }
}

const keycloak = new Keycloak({
  url: getBaseUrl(),
  realm: "smartcityhub",
  clientId: "portal-application",
});

export default keycloak;
