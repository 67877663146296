import React, {useEffect} from "react";
import {useKeycloak} from "@react-keycloak/web";

function Logout() {
  const {keycloak} = useKeycloak();

  useEffect(() => {
    keycloak.logout({redirectUri: window.location.origin + "/"});
  }, []);

  return <></>;
}

export default Logout;
