import React, {useEffect} from "react";
import {useKeycloak} from "@react-keycloak/web";

function AccountManage() {
  const {keycloak} = useKeycloak();

  useEffect(() => {
    const accountUrl = keycloak.createAccountUrl({
      redirectUri: window.location.origin,
    });
    window.location.href = accountUrl;
  }, []);

  return <></>;
}

export default AccountManage;
