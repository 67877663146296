import React, {ReactElement, useEffect, useState} from "react";

export const ScreenSize = React.createContext<ScreenSizeInfo>({
  category: "Mobile",
  oCategory: "Mobile",
  width: 400,
});

type ScreenSizeInfo = {
  /** Screen type based solely on its width */
  category: "Mobile" | "Tablet" | "Desktop";
  /** Screen type to use on overview pages with adjusted breakpoints to account for sidebar and padding */
  oCategory: "Mobile" | "Tablet" | "Desktop";
  /** Screen width in px */
  width: number;
};

function ScreenSizeProvider({children}: {children: ReactElement}) {
  const getSize: () => ScreenSizeInfo = () => {
    const {innerWidth: width} = window;
    return {
      category: width >= 1272 ? "Desktop" : width >= 768 ? "Tablet" : "Mobile",
      oCategory: width >= 1272 ? "Desktop" : width >= 664 ? "Tablet" : "Mobile",
      width,
    };
  };
  const [size, setSize] = useState<ScreenSizeInfo>(getSize());

  useEffect(() => {
    const handleResize = () => {
      setSize(getSize());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <ScreenSize.Provider value={size}>{children}</ScreenSize.Provider>;
}

export default ScreenSizeProvider;
