import React from "react";
import {FormattedMessage} from "react-intl";
import {Applications} from "../../logic/Types";
import treelights from "../../images/treelights.png";
import nextcloud from "../../images/nextcloud.png";
import datamarket from "../../images/datamarket.png";
import "./Home.css";
import {Resolution} from "../../logic/API";
import {ApplicationButton} from "@spg/component_library";
import news1 from "../../images/news1.jpg";
import news2 from "../../images/news2.jpg";
import news3 from "../../images/news3.jpg";
import SearchBar from "../../reusables/SearchBar";

type HomeProps = {
  /** Status of the request for the list of applications */
  applications: Resolution<Applications>;
};

/**
 * A page with a list of links to other applications.
 */
function Home(props: HomeProps) {
  const dummyNews = {
    1: {
      title: "Baumampel mit neuen Features",
      description: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
      url: "/",
    },
    2: {
      title: "Neue Keycloakversion",
      description: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
      url: "/",
    },
    3: {
      title: "Heimatforum bekommt neues Erscheinungsbild",
      description: "Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.",
      url: "/",
    },
  };
  return (
    <div className="homeWrapper">
      <h1 className="homeTitleHeading">
        <FormattedMessage id="overview.home.title" />
      </h1>
      <div className="homeSearchBarContainer">
        <SearchBar idBase="home" placeholder="overview.home.search" />
      </div>
      <div className="homeApplicationsContainer">
        {props.applications.success &&
          props.applications.payload &&
          Object.keys(props.applications.payload).length > 0 &&
          Object.values(props.applications.payload).map((s, index) => {
            if (s.name !== "Smart City Hub Würzburg") {
              return (
                <React.Fragment key={s.id}>
                  <ApplicationButton
                    idBase={s.name.toLowerCase() + "Home"}
                    href={s.url}
                    image={[treelights, nextcloud, datamarket][index % 3]}
                    caption={s.name}
                    size="big"
                  />
                </React.Fragment>
              );
            }
          })}
      </div>
      <div className="homeActivityContainer">
        <div className="homeActivityHeading">
          <FormattedMessage id={"overview.home.activities"} />
        </div>
        <div className="homeActivities">
          {Object.keys(dummyNews).length > 0 &&
            Object.values(dummyNews).map((s, index) => (
              <NewsEntry
                key={index}
                title={s.title}
                description={s.description}
                image={[news1, news2, news3][index]}
                url={s.url}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Home;

type NewsEntryProps = {
  title: string;
  description: string;
  image: string;
  url: string;
};
function NewsEntry(props: NewsEntryProps) {
  return (
    <a className="newsEntryContainer" href={props.url}>
      <div className="newsImageContainer">
        <img src={props.image} alt=""></img>
      </div>
      <div className="newsTitleContainer">{props.title}</div>
      <div className="newsCaptionContainer">{props.description}</div>
    </a>
  );
}
