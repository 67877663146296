import React, {useEffect, useState} from "react";
import "./Overview.css";
import Home from "./pages/Home";
import {Applications} from "../logic/Types";
import API, {Resolution} from "../logic/API";
import {useKeycloak} from "@react-keycloak/web";
import {Button, GlobalMenu} from "@spg/component_library";
import {User} from "../logic/Types";
import {useIntl} from "react-intl";
import {NavLink} from "react-router-dom";

/**
 * A view with a topbar to navigate to other applications and the account management
 */
function Overview() {
  const {keycloak} = useKeycloak();
  const intl = useIntl();

  const [applications, setApplications] = useState<Resolution<Applications>>({
    success: false,
    unresolved: true,
  });
  const [userInfo, setUserInfo] = useState<User>({
    firstName: "–",
    lastName: " ",
    email: "–",
  });

  const apiCall = async () => {
    setApplications(await API.getApplications());
  };

  useEffect(() => {
    apiCall();
  }, []);

  useEffect(() => {
    if (keycloak.idTokenParsed)
      setUserInfo({
        firstName: keycloak.idTokenParsed["given_name"],
        lastName: keycloak.idTokenParsed["family_name"],
        email: keycloak.idTokenParsed["email"],
      });
  }, [keycloak.idTokenParsed]);

  return (
    <div className="overviewContainer">
      <div className="overviewGlobalMenu">
        <GlobalMenu
          isLoggedIn
          firstName={userInfo.firstName}
          lastName={userInfo.lastName}
          email={userInfo.email}
          applications={applications.payload}
          altTextApplications={intl.formatMessage({
            id: "reusables.globalMenu.applications",
          })}
          emptyMessage={intl.formatMessage({
            id: applications.unresolved
              ? "reusables.globalMenu.applications.loading"
              : "reusables.globalMenu.applications.empty",
          })}
          ariaLabelUserImage={intl.formatMessage({
            id: "reusables.globalMenu.userWidget",
          })}
          primaryButton={
            <NavLink to="/account" tabIndex={-1}>
              <Button
                label={intl.formatMessage({
                  id: "reusables.globalMenu.userWidget.management",
                })}
                idBase={"overviewGlobalMenuAccountManagement"}
                onClick={() => null}
                configuration={"primary"}
              />
            </NavLink>
          }
          secondaryButton={
            <NavLink to="/logout" className="overviewGlobalMenuNavLink">
              {intl.formatMessage({
                id: "reusables.globalMenu.userWidget.logout",
              })}
            </NavLink>
          }
          toggleNameTooltipMessage={intl.formatMessage({
            id: "reusables.globalMenu.userWidget.toggleName",
          })}
          toggleEmailTooltipMessage={intl.formatMessage({
            id: "reusables.globalMenu.userWidget.toggleMail",
          })}
        />
      </div>
      <div className="overviewContent">
        <Home applications={applications} />
      </div>
    </div>
  );
}

export default Overview;
